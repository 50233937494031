import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import kebabCase from 'kebab-case'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ArticleTemplate = ({
  content,
  contentComponent,
  coverImage,
  tags,
  title,
  adventurers,
  startDate,
  endDate,
}) => {
  const PostContent = contentComponent || Content

  return (
    <Layout>
      <article>
        <h2 className="title">{title}</h2>
        {coverImage && (
          <Img fluid={coverImage.childImageSharp.fluid} alt={title} />
        )}
        <p>
          Adventurers:{' '}
          {adventurers.map((name, i, arr) => (
            <span key={name}>
              {name}
              {arr.length - 1 !== i && ', '}
            </span>
          ))}
        </p>
        <p>
          {startDate} - {endDate}
        </p>
        <PostContent content={content} />
        {tags && tags.length ? (
          <div style={{ marginTop: `4rem` }}>
            <h4>Tags</h4>
            <ul className="taglist">
              {tags.map(tag => (
                <li key={tag + `tag`}>
                  <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </article>
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  coverImage: PropTypes.object,
  meta_title: PropTypes.string,
  meta_desc: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  adventurers: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

const ArticlePage = ({ data: { markdownRemark: post } }) => (
  <ArticleTemplate
    content={post.html}
    contentComponent={HTMLContent}
    coverImage={post.coverImage}
    meta_title={post.frontmatter.meta_title}
    meta_desc={post.frontmatter.meta_description}
    tags={post.frontmatter.tags}
    title={post.frontmatter.title}
    slug={post.frontmatter.slug}
    adventurers={post.frontmatter.adventurers}
    startDate={post.frontmatter.startDate}
    endDate={post.frontmatter.endDate}
  />
)

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      coverImage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frontmatter {
        slug
        title
        tags
        startDate(formatString: "DD/M/YY")
        endDate(formatString: "DD/M/YY")
        adventurers
      }
    }
  }
`
